import React, { useState } from "react";
import background from "./imgs/barca.jpg";
import logo from "./imgs/logo_192.png";
import twitter from "./imgs/twitterx_logo.png";
import telegram from "./imgs/telegram_logo.png";
import qrcode from "./imgs/mm-donation-address.png";
import { ReactComponent as Catalan } from "./imgs/catalonia-flag.svg";
import { styled, css } from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Flex = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${Flex}
  background-image: url(${background});
  background-color: #cccccc;
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Wrapper = styled.div`
  ${Flex}
  flex-direction: column;
  backdrop-filter: blur(2px);
  height: 100%;
  width: 100%;
  gap: 20px;
  text-align: center;
  span {
    ${Flex}
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    color: #fff;
    padding: 20px 40px;
    margin: 0 20px;
  }
  h1,
  h2 {
    margin: 0;
    padding: 10px;
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    ${Flex}
  }
  img {
    max-width: 50vmin;
    max-height: 30vh;
  }
  .socials {
    ${Flex}
    gap: 20px;
    img {
      width: 50px;
      height: 50px;
    }
  }
`;

const Button = styled.button`
  text-decoration: none;
  background: none;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 1em;
  text-transform: uppercase;
  &.light {
    border: 1px solid #fff;
    background-color: #ff6600;
    color: #4c4c4c;
    &:hover {
      border: 1px solid #ff6600;
      background: none;
      color: #fff;
    }
    &:active {
      border: 1px solid #fff;
      background: #ff6600;
      color: #fff;
    }
  }
  &.dark {
    border: 1px solid #4c4c4c;
    background-color: #4c4c4c;
    color: #ff6600;
    &:hover {
      border: 1px solid #4c4c4c;
      background: none;
      color: #4c4c4c;
    }
    &:active {
      border: 1px solid #fff;
      background: #ff6600;
      color: #fff;
    }
  }
`;

const QrContainer = styled.div`
  position: absolute;
  index: 100;
  ${Flex}
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #ff6600;
`;

const QrWrapper = styled.div`
  position: relative;
  width: 95%;
  // max-height: 90vh;
  ${Flex}
  flex-direction: column;
  gap: 20px;
  text-wrap: wrap;
  img {
    // width: 60vmin;
    // max-width: 300px;
    max-height: 50vh;
    max-width: 90vw;
  }
  h2 {
    margin: 0;
    padding: 0;
    text-align: center;
    text-wrap: wrap;
  }
  text {
    max-width: 90%;
    font-size: 2vmin;
    text-align: center;
    text-wrap: wrap;
    word-wrap: break-word;
  }
  span {
    ${Flex}
    gap: 20px;
  }
`;

function App() {
  const [clicked, setClicked] = useState(false);
  return (
    <>
      {clicked ? (
        <QrContainer>
          <QrWrapper>
            <h2>
              Scan with Cake Wallet to help with the costs of the meetups 👇
            </h2>
            <img src={qrcode} alt="Monero Meetups Donation Address QR" />
            <h2>Or copy-pasta 🍝 directly 🖱</h2>
            <span>
              <Button
                className="dark"
                onClick={() => {
                  setClicked(false);
                }}
              >
                back
              </Button>
              <CopyToClipboard text="49D27tANZac3j9oPwTe6EZ4FvaAKyaXR1aLR7XLq5GEK6bhq9Jfk5drbLGwVDLmq4t1nnKUwphWxUXqvB398qWVHD3GACqJ">
                <Button className="dark">Copy to clipboard</Button>
              </CopyToClipboard>
            </span>
            <text>
              49D27tANZac3j9oPwTe6EZ4FvaAKyaXR1aLR7XLq5GEK6bhq9Jfk5drbLGwVDLmq4t1nnKUwphWxUXqvB398qWVHD3GACqJ
            </text>
          </QrWrapper>
        </QrContainer>
      ) : (
        <Container>
          <Wrapper>
            <img src={logo} alt="Monero Meetups Logo" />
            <span>
              <h1>Monero Meetups</h1>
              <h2>
                <Catalan />
                BARCELONA
                <Catalan />
              </h2>
              <h3>
                Meetups, Events, Workshops, and P2P Hub for Individuals and
                Vendors.
              </h3>
              <div className="socials">
                <a
                  href="https://twitter.com/MoneroBcn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="Monero Meetups Twitter Link" />
                </a>
                <a
                  href="https://t.me/MoneroBcn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={telegram}
                    alt="Monero Meetups Telegram Group Link"
                  />
                </a>
              </div>
            </span>
            <Button
              className="light"
              onClick={() => {
                setClicked(true);
              }}
            >
              contribute
            </Button>
          </Wrapper>
        </Container>
      )}
    </>
  );
}

export default App;
